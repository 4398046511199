import { writable } from 'svelte/store';

export let api="/php_lib/eshop.api.php";
if((window.location.href).includes('localhost:5000')) { api="http://vesele-bylinky.cz/php_lib/eshop.api.php"; }

export let api_url = writable(api);
export let api_cities_url = writable("https://epublis.jahnet.eu/cities/?text=");
export let path = writable("eshop/");
export let real_path = writable("");
//export let template = writable("classic");
export let template = writable("bootstrap");
export let bgColor = writable("rgba( 199, 0, 57,.9)");
export let boxColor = writable("rgba(255,255,255,0.7)");
export let categoryColor = writable("rgba( 39, 55, 70 ,.7)");
export let borderColor = writable("rgba( 218, 247, 166,0,8)");
export let headerColor = writable("#d7d7d7");
export let tableColor = writable("#EEEEEE");
export let textColor = writable("blue");
export let textColorInBg = writable("white");
