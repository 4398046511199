<script>
    import { bgColor, textColorInBg, borderColor } from "../ConfigService.js";
    import { Ripple } from 'svelte-mui';
    export let active=false;
    export let passive=false;
    let bg_color="#1976d2";
    let text_color="white";
    let border_color="#3590db";
    if($bgColor) { bg_color=$bgColor; }
    if($textColorInBg) { text_color=$textColorInBg ; }
    if($borderColor) { border_color=$borderColor; }

</script>

{#if passive}
  <div class="box filled" style="--bg-color: {bg_color}; --text-color: {text_color}; --border-color: {border_color};">
      <slot />
      <Ripple center />
  </div>
{:else if active}
  <div class="box filled pointer" style="--bg-color: {bg_color}; --text-color: {text_color}; --border-color: {border_color};">
      <slot />
      <Ripple center />
  </div>
{:else}
  <div class="box bordered pointer" style="--bg-color: {bg_color}; --border-color: {border_color};">
      <slot />
      <Ripple center />
  </div>
{/if}

<style>
    .box {
        background:rgba(255,255,255,0.8); 
        user-select: none;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid var(--border-color);
        display: inline-block;
        margin: 0 1rem 1rem 0;
        padding: 0.75rem;
    }
    .box
    .box :global(.ripple) {
        border-radius: 0 !important;
    }
    .filled {
        color: var(--text-color);
        background: var(--bg-color);
    }
    .bordered {
        color: var(--bg-color);
    }

    .pointer { cursor: pointer; }
    .pointer:hover { opacity:.8; }
</style>
