<script>
  import { Link } from "svelte-routing";
  import Loading from "../components/Loading.svelte";
  import Box from "../components/Box.svelte";
  import AddToCart from "../components/AddToCart.svelte";
  import OrderBy from "../components/OrderBy.svelte";
  import Podkategorie from "../routes/Podkategorie.svelte";
  import { api_url, path, boxColor } from "../ConfigService.js";

  export let katId = "";
  export let objectId = "favorites";
  export let page = 1;
  export let config;
  export let cartData;
  export let loginData;
  export let returnPage=0;
  export let orderBy=false;
  export let orderType=false;
  let box_color="#6f4a5d";
  if($boxColor) { box_color=$boxColor; }

	let data = (async () => {
    let thisPage=parseInt((await page)-1);
    if(thisPage<0) {thisPage=0;}
		const response = await fetch($api_url+'?kat='+objectId+'&page='+thisPage+'&orderBy='+orderBy+'&orderType='+orderType);
    returnPage=await page;
    return await response.json()
	})()

  async function update() {
    if(await page!=returnPage) {
      let thisPage=parseInt((await page)-1);
      if(thisPage<0) {thisPage=0;}
      const res = await fetch($api_url+'?kat='+objectId+'&page='+thisPage+'&orderBy='+orderBy+'&orderType='+orderType);
      data = await res.json();
      returnPage=await page;
    }
  }

  $: {
    update(orderBy);
  }

</script>

{#await data}
	<Loading />
{:then data}
  <div class="navigation_bar">
    <Link to="{$path}">{config.text.title}</Link> -&gt;
    {data.info.nazev}
  </div>
  <h2 class="nadpis">{data.info.nazev}</h2>
    <div class="eshop_produkty">
    {#each data.data as p}
      <Box className="eshop_produktBox" style="--box-color: {box_color}">
  	   <h3>
         <Link to="{$path}f/p/p/{p.url}">
          {p.nazev}
         </Link>
       </h3>
       <h4>{p.vyrobce}</h4>
       {#if p.img}
        <img src="../../{config.folder}/{p.img}" alt="{p.nazev}" width="80">
       {:else}
        <img src="../../{config.noimg}" alt="{p.nazev}" width="80">
       {/if}
       {#if p.popis}
        <p>{p.popis}</p>
       {/if}
       {#if p.cena!=0}
          <div class="eshop_price">{config.text.price} {p.cena},-Kč</div><br>
       {/if}
       <AddToCart id={p.id} text={config.text.addToCart} isFavorite="true" bind:cartData bind:loginData />
       <small>  </small>
      </Box>
    {/each}
    </div>
  <br>
  <Link to="{$path}">Zpět na úvodní stranu</Link>
  <br>
{:catch error}
	<p>Chyba načítání!</p>
{/await}

<style>
.eshop_produkty { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; }
.eshop_produktBox { border:2px solid #6f4a5d; width:300px; float:left; margin:10px; padding:10px; border-radius:5px; }
</style>
