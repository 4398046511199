<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";
  import { api_url, path } from "../ConfigService.js";
  import CartEmailDialog from '../dialog/CartEmailDialog.svelte';
  import CartAdressDialog from '../dialog/CartAdressDialog.svelte';
  import ChangePwDialog from '../dialog/ChangePwDialog.svelte';

  export let config;
  export let loginData;
  export let title="přihlásit se";
  export let url="/";
  if(config.text.login) { title=config.text.login; }
  export let visibleDialog=false;
  export let visibleAdressDialog=false;
  export let visiblePwDialog=false;

  async function logout() {
     const response = await fetch($api_url+'?customer=true&logout=true');
     loginData.id=false;
     loginData = await response.json();
   }
</script>

{#await loginData}
    &nbsp;
{:then loginData}
  <div class="eshop_login">
    {#if !loginData.id}
      <a on:click={() => (visibleDialog = true)}>
        {title}
      </a>
    {:else}
      <ul>
        <li><b>Přihlášen:</b> {loginData.jmeno} {loginData.prijmeni}</li>
        <li><Link to="{$path}cart/orders">mé objednávky</Link></li>
        <li><Link to="{$path}cart/favorites">oblíbené produkty</Link></li>
        <li><a on:click="{() => visibleAdressDialog=true}">změnit osobní údaje</a></li>
        <li><a on:click="{() => visiblePwDialog=true}">změnit heslo</a></li>
        <li><a on:click={logout}>odhlásit se</a></li>
      </ul>
    {/if}
  </div>
  <CartAdressDialog bind:visibleAdressDialog onlyEdit="true" loginData={loginData} config={config} />
{:catch error}
{/await}

<CartEmailDialog bind:visibleDialog bind:loginData config={config} onlyLogin="true" />
<ChangePwDialog bind:visiblePwDialog config={config} />

<style>
a {cursor:pointer; text-decoration:underline;}
a:hover {text-decoration:none;}
.eshop_login {text-align:right;}
.eshop_login ul {
    list-style-type: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -1px;
}
.eshop_login li {
    flex-grow: 1;
    flex-basis: auto;
    margin: .25em 0;
    padding: 0 1em;
    text-align: center;
    border-left: 1px solid #ccc;
    display:inline;
}

</style>
