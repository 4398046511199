<script>
  import { onMount } from "svelte";
  import NavLink from "./components/NavLink.svelte";
	import Loading from "./components/Loading.svelte";
	import { api_url, path, template, bgColor, boxColor, categoryColor, borderColor, headerColor, tableColor, textColor, textColorInBg } from "./ConfigService.js";
  import Classic from "./Classic.svelte";
  import Bootstrap from "./Bootstrap.svelte";

  let config;
  async function updateConfig() {
      const res = await fetch($api_url+'?config=true');
      config = await res.json();
  }

  //loginData
  let loginData = (async () => {
     const response = await fetch($api_url+'?customer=true')
     return await response.json()
 })()

 async function updateLogin() {
     const res = await fetch($api_url+'?customer=true');
     loginData = await res.json();
 }

 //cartData
 let cartData = (async () => {
     if((window.location.href).includes('localhost:5000')) {
       const response = await fetch($api_url+"?democart=true");
       return await response.json()
     }
     else {
       const response = await fetch($api_url+"?cart=true");
       return await response.json()
     }
})()

async function updateCart() {
    const res = await fetch($api_url+'?cart=true');
    cartData = await res.json();
}
onMount(() => {
  updateConfig();
  const intervalUpdate = setInterval(() => {
    if(!cartData) {
       updateCart();
     }
     if(!loginData) {
        updateLogin();
      }
  }, 500);
  return () => clearInterval(intervalUpdate);
});


$: {
  if(config) {
    if(config.path) { path.set(config.path+"/"); }
    if(config.template) {
      if(config.template.template) { template.set(config.template.template); }
      if(config.template.bgColor) { bgColor.set(config.template.bgColor); }
      if(config.template.boxColor) { boxColor.set(config.template.boxColor); }
      if(config.template.categoryColor) { categoryColor.set(config.template.categoryColor); }
      if(config.template.borderColor) { borderColor.set(config.template.borderColor); }
      if(config.template.headerColor) { headerColor.set(config.template.headerColor); }
      if(config.template.tableColor) { tableColor.set(config.template.tableColor); }
      if(config.template.textColor) { textColor.set(config.template.textColor); }
      if(config.template.textColorInBg) { textColorInBg.set(config.template.textColorInBg); }
    }
  }
}
</script>

{#await config}
	<Loading />
{:then config}
  {#if config && loginData}
    {#if $template=="bootstrap"}
  	  <Bootstrap {config} bind:loginData bind:cartData />
    {:else}
      <Classic {config} bind:loginData bind:cartData />
    {/if}
  {:else}
    <Loading />
  {/if}
{:catch error}
	<p>Chyba načítání!</p>
{/await}
