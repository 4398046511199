<script>
  import { Textfield, Button } from 'svelte-mui/src';
  import { Link } from "svelte-routing";
  import Box from "../components/Box.svelte";
  import { path, bgColor, borderColor } from "../ConfigService.js";

  let bg_color="#1976d2";
  let border_color="#3590db";
  if($bgColor) { bg_color=$bgColor; }
  if($borderColor) { border_color=$borderColor; }

  export let config;
  export let search;
  let value;
  let title="Co hledáte?";
  let button="Vyhledat";
  if(config.text.searchInput) { title=config.text.searchInput; }
  if(config.text.searchButton) { button=config.text.searchButton; }

  function sendSearch() {
    search=value;
  }
</script>
<br>
<div class="eshop_search" style="--bg-color: {bg_color}">
<Box width="350">
  <form>
    <input type="text"
      autocomplete="off"
      placeholder={title}
      bind:value
    />
    <Link to="{$path}search/{search}"><button on:click={sendSearch}>{button}</button></Link>
  </form>
</Box>
</div>

<style>
.eshop_search input { width:200px; }
.eshop_search button { background-color:white; }
.eshop_search button:hover { background-color:var(--bg-color); opacity:.9; }
.eshop_search input, .eshop_search button {
    border: 2px solid var(--bg-color);
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
}
.eshop_search { text-align:right; float:right; }
</style>
