<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";
  import { Button } from 'svelte-mui/src';
  import Loading from "../components/Loading.svelte";
  import Box from "../components/Box.svelte";
  import AddToCart from "../components/AddToCart.svelte";
  import Pagination from "../components/Pagination.svelte";
  import OrderBy from "../components/OrderBy.svelte";
  import FilterLabels from "../components/FilterLabels.svelte";
  import Filters from "../dialog/Filters.svelte";
  import Label from '../components/Label.svelte';
  import InStock from '../components/InStock.svelte';
  import IoIosOptions from 'svelte-icons/io/IoIosOptions.svelte'
  import { api_url, path, real_path, bgColor, boxColor } from "../ConfigService.js";

  export let search = false;
  export let searchId="";
  export let page = 1;
  export let config;
  export let cartData;
  export let loginData;
  export let returnPage=0;
  export let orderBy=false;
  export let orderType=false;
  export let filter="";
  export let visibleFilterPanel=false;
  let lastFilter="";
  let changeOrder=false;
  let visibleLoading=false;
  let box_color="#6f4a5d";
  if($boxColor) { box_color=$boxColor; }
  let thisPage;
  let lastSearch;
  export let apiUrl;
  let errorTitle="Omlouváme se, ale nebyly nalezeny žádné produkty!";
  if(config.text.noproducts) { errorTitle=config.text.noproducts; }

	let data = (async () => {
    let filterUrl="";
    if(filter) { filterUrl="&filter="+filter; }
		const response = await fetch(apiUrl+filterUrl);
    returnPage=await page;
    lastFilter=filter;
    lastSearch=search;
    return await response.json()
	})()

  async function update() {
    if(await page!=returnPage || changeOrder || lastFilter!=filter || lastSearch!=search) {
      visibleLoading=true;
      let filterUrl="";
      if(filter) { filterUrl="&filter="+filter; }
      const res = await fetch(apiUrl+filterUrl);
      //console.log(apiUrl+filterUrl);
      data = await res.json();
      returnPage=await page;
      lastFilter=filter;
      lastSearch=search;
      changeOrder=false;
      if(await res) { visibleLoading=false; }
    }
  }

  $: {
    update(search, orderBy, orderType, filter, lastFilter);
    thisPage=parseInt((page)-1);
    if(thisPage<0) {thisPage=0;}
    apiUrl=$api_url+'?search='+search+'&page='+thisPage+'&orderBy='+orderBy+'&orderType='+orderType;
  }

  onMount(() => {
      if(!search) { search=searchId; }
  });
</script>

{#await data}
	<Loading />
{:then data}
  <div class="navigation_bar">
    <Link to="{$path}">{config.text.title}</Link> -&gt;
    {config.text.search}
  </div>
  <h2 class="nadpis">{config.text.searchTitle}</h2>
  <br><br>
  <span on:click={update}>
    <Pagination count={ data.info.pages } active={page} kat="search" podkat={search} />
  </span>
  <OrderBy bind:orderBy bind:orderType bind:changeOrder />
  <Loading bind:visible={visibleLoading} />
  {#if !visibleLoading}
    {#if config.filters}
      <Filters {apiUrl} {config} bind:visible={visibleFilterPanel} bind:filter bind:lastFilter />
      <div style="margin-left:30px; width:250px; float:left;">
        <Button on:click="{()=>visibleFilterPanel=true}" color={$bgColor} outlined raised style="width:260px;"><span style="width:25px"><IoIosOptions /></span>&nbsp;&nbsp;Filtrování produktů</Button>
      </div>
    {/if}
    <FilterLabels data={ data.filters.checkbox } bind:filter title={config.text.filterLabels} />
    <br style="clear:both"><br>
    <div class="eshop_produkty">
    {#if data.data.length==0}
      <b>{errorTitle}</b>
    {:else}
      {#each data.data as p}
        <Box className="eshop_produktBox" style="--box-color: {box_color}">
    	   <h3>
           <Link to="{$path}search/{search}/p/{p.url}">
            {p.nazev}
           </Link>
         </h3>
         <h4>{p.vyrobce}</h4>
         {#if p.img}
          <img src="/{$real_path}{config.folder}/{p.img}" alt="{p.nazev}" width="100%">
         {:else}
          <img src="/{$real_path}{config.noimg}" alt="{p.nazev}" width="100">
         {/if}
         <AddToCart id={p.id} text={config.text.addToCart} favoriteList={data.favorites} bind:cartData bind:loginData />
         {#if p.popis}
          <p>{p.popis}</p>
         {/if}
         {#if p.cena!=0}
            <div class="eshop_price">{config.text.price} {p.cena},-Kč</div><br>
         {/if}
         {#if config.showInStockInList}
            <InStock count={p.pocet} mode={config.showInStock} />
         {/if}
         <br>
         {#if config.labelsInList}
           {#if data.filters.checkbox}
             {#each data.filters.checkbox as label}
              {#if label.values}
                {#if label.values[(p.id)]}
                 <Label passive=true>{label.title}</Label>
                {/if}
              {/if}
             {/each}
          {/if}
        {/if}
        </Box>
      {/each}
    {/if}
    </div>
    <span on:click={update}>
      <Pagination count={ data.info.pages } active={page} kat="search" podkat={search} />
    </span>
  {/if}
  <br>
  <Link to="{$path}">Přejít na kategorie</Link>
  <br>
{:catch error}
	<p>Chyba načítání!</p>
{/await}

<style>
.eshop_produkty { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; }
.eshop_produktBox { border:2px solid var(--box-color); width:300px; float:left; margin:10px; padding:10px; border-radius:4px; }
</style>
