<script>
  import { Link } from "svelte-routing";
  import Loading from "../components/Loading.svelte";
  import Box from "../components/Box.svelte";
  import { api_url, path, textColorInBg, categoryColor } from "../ConfigService.js";

  export let objectId = "";
  export let activePodkat=false;
  export let config;
  let text_color="white";
  let box_color="#6f4a5d";
  if($textColorInBg) { text_color=$textColorInBg; }
  if($categoryColor) { box_color=$categoryColor; }

	const data = (async () => {
		const response = await fetch($api_url+'?pk='+objectId)
    return await response.json()
	})()
</script>

{#await data}
	<Loading />
{:then data}
  {#if !activePodkat}
    <div class="navigation_bar">
      <Link to="{$path}">{config.text.title}</Link> -&gt;
      {data[0].nazev}
    </div>
    <h2 class="nadpis">{data[0].nazev}</h2>
  {/if}
  <div class="eshop_kategorie eshop_podkategorie" style="--text-color: {text_color}">
  {#each data[0].kategorie as pk}
    <Box background="{box_color}" width="300">
    <div class="eshop_kategorieBox eshop_podkategorieBox">
	   <h3>
      {#if activePodkat==pk.url}
        {pk.nazev}
      {:else}
         <Link to="{$path}{objectId}/{pk.url}/0">
          {pk.nazev}
         </Link>
       {/if}
     </h3>
    </div>
    </Box>
  {/each}
  </div>
  {#if !activePodkat}
    <br>
    <Link to="{$path}">Zpět na kategorie</Link>
  {/if}
{:catch error}
	<p>Chyba načítání!</p>
{/await}

<style>
.eshop_kategorie { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; color:var(--text-color); }
.eshop_kategorieBox { width:300px; float:left; margin:10px; padding:10px; border-radius:4px; font-size:20px; }
.eshop_kategorieBox h3 { width:100%; padding:10px; position:relative; left:-28px; top:-34px; margin-bottom:-20px; text-align:center; text-transform: uppercase; }
</style>
