<script>
  import { bgColor } from "../ConfigService.js";
  export let orderBy=false;
  export let orderType="asc";
  export let changeOrder=false;

  let selected=[ { id: 1, text: 'Výchozí řazení', by:"false", type:"asc" }];

  let options = [
    { id: 1, text: 'Výchozí řazení', by:"false", type:"asc" },
    { id: 2, text: 'Dle názvu', by:"nazev", type:"asc" },
    { id: 3, text: 'Od nejlevnějšího', by:"cena", type:"asc"  },
    { id: 4, text: 'Od nejdražšího', by:"cena", type:"desc"  }
  ];

  function selectedOrder() {
    if(selected) {
      orderBy=selected.by;
      orderType=selected.type;
      changeOrder=true;
    }
  }
</script>

<div class="eshop_orderBy">
<form>
	<select bind:value={selected} on:change={selectedOrder} style="--bg-color: {$bgColor}">
		{#each options as option}
			<option value={option}>
				{option.text}&nbsp;&nbsp;
			</option>
		{/each}
	</select>
</form>
</div>
<br style="clear:both">

<style>
.eshop_orderBy { float:right; padding-right:60px; }
.eshop_orderBy select {
    border: 2px solid var(--bg-color);
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
}
</style>
