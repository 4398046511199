<script>
  import { headerColor, tableColor } from "../ConfigService.js";
  export let data;
  let td1Color="#EEEEEE";
  let td2Color="silver";
  if($headerColor) { td1Color=$headerColor; }
  if($tableColor) { td2Color=$tableColor; }
</script>

{#if data}
  <br style="clear:both"><br><br>
  <table class="eshop_paramTable" style="--td1-color: {td1Color}; --td2-color: {td2Color};">
  {#each data as p}
    {#if p.type!="checkbox"}
      <tr>
        <td>{p.name}</td>
        <td class="right">
          {p.value}
          {#if p.note}
            {p.note}
          {/if}
        </td>
      </tr>
    {/if}
  {/each}
  </table>
  <br>
{/if}

<style>
table, th, td {
		border: 1px solid;
		border-collapse: collapse;
		margin-bottom: 10px;
    font-size: 16px;
}

table.eshop_paramTable {
	border: 1px solid #1C6EA4;
	background-color: var(--td1-color);
	width: 80%;
  max-width:450px;
	text-align: left;
	border-collapse: collapse;
}
table.eshop_paramTable td {
	border: 1px solid #AAAAAA;
	padding: 8px 12px 8px 12px;
}
table.eshop_paramTable tr:nth-child(even) {
  background-color: var(--td2-color);
}
.right { text-align:right }
</style>
