<script>
	export let item = undefined;
	export let getOptionLabel = undefined;
</script>

<div class="findItem">
  <div class="findItem_title">
    <div class="findItem_name">{item.mesto}</div>
    <div class="findItem_tagline">{item.cast}</div>
  </div>
</div>

<style>
  .findItem {
    display: flex;
    align-items: center;
  }
  .findItem_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor:pointer;
  }

  .findItem_name {
    display: inline-block;
    font-weight: 700;
    margin-right: 10px;
  }

  .findItem_tagline {
    display: inline-block;
  }
</style>
