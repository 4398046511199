<script>
  import { Link } from "svelte-routing";
  import Loading from "../components/Loading.svelte";
  import Box from "../components/Box.svelte";
  import { Button, Snackbar } from 'svelte-mui/src';
  import { api_url, path } from "../ConfigService.js";

  export let cartData;
  export let config;

  const data = (async () => {
    const response = await fetch($api_url+"?customer=true&orders=true")
    return await response.json()
  })()

  function stav(s) {
    if(s=="0") { return "Objednávka byla přijata"; }
    else if(s=="1") { return "Objednávka se zpracovává"; }
    else if(s=="2") { return "Objednávka byla odeslána"; }
    else if(s=="3") { return "Objednávka byla vyřízena"; }
    return "";
  }

  function doprava(doprava, platba, dop) {
    let out="";
    if(dop) {
      dop.forEach(async function(d) {
        if(d.id==doprava) { out+=d.nazev; }
        d.platba.forEach(async function(p) {
          if(p.id==platba) { out+=" ("+p.nazev+")"; }
        })
      })
    }
    return out;
  }
</script>

<div class="navigation_bar">
  <Link to="{$path}">{config.text.title}</Link> -&gt;
  objednávky
</div>
<h2 class="nadpis">Mé objednávky</h2>
{#await data}
  <Loading />
{:then data}
  <div class="eshop_data">
  {#if data}
  {#each data as order}
    <Box className="eshop_dataBox mgb" width="90%" maxWidth="1200" marginAuto=true>
      <div class="eshop_order_info">
        <b>Datum:</b> {order.datum}<br>
        <b>Stav:</b> <i>{stav(order.stav)}</i><br>
        <b>Celková cena:</b> {order.cena},-<br>
        <b>Adresa:</b> {order.ulice}, {order.mesto}<br>
        {#await cartData}
        	&nbsp;
        {:then cartData}
          {#if cartData.doprava}
              <b>Doprava:</b> {doprava(order.doprava, order.platba, cartData.doprava)} <br>
          {/if}
        {/await}
      </div>
      <table class="eshop_order_table">
        {#if order.eshop_produkty}
          {#each order.eshop_produkty as prod}
            <tr>
              <td><Link to="{$path}o/p/p/{prod.url}">{prod.nazev}</Link></td>
              <td>{prod.count}ks</td>
              <td>{prod.cena},-</td>
            </tr>
          {/each}
        {/if}
      </table>
      <br>
      <br style="clear:both">
    </Box><br>
  {/each}
  {/if}
  </div>
{:catch error}
	<p>Chyba načítání!</p>
{/await}

<style>
.eshop_dataBox {
    border: 1px solid #6f4a5d;
    margin: 10px auto 10px auto;
    padding: 10px;
    border-radius: 5px;
    text-align: left;
    min-height: 80px;
    max-width:1200px;
}
.eshop_order_info { float:left; }
.eshop_order_table { float:right; }
.eshop_order_table td { border:1px solid silver; padding:15px;}
</style>
