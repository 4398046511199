<script>
    import Label from './Label.svelte';
    export let data;
    export let filter;
    export let title="";
    export let filterChanged=false;

    function getFilterValue(type) {
      let f = filter.split(";");
      for(let i=0;i<f.length;i++) {
        if(f[i].includes(type+"=")) {
          let value = f[i].split("=");
          return value[1];
        }
      }
      return false;
    }

    function removeFromFilter(remove) {
      let f = filter.split(";");
      filter="";
      for(let i=0;i<f.length;i++) {
        if(!f[i].includes(remove+"=")) {
          if(filter!="") { filter+=";"; }
          filter+=f[i];
        }
      }
      if(filter=="") { filter="false"; }
      filterChanged=true;
    }

    function addToFilter(type, value) {
      removeFromFilter(type);
      if(filter=="false") { filter=""; }
      else if(filter!="") { filter+=";"; }
      filter+=type+"="+value;
      filterChanged=true;
    }
</script>

{#if data}
  <div class="eshop_filter_labels">
    <b>{title}</b>
    {#each data as label}
      {#if getFilterValue(label.id) && !filterChanged}
        <span on:click="{()=>removeFromFilter(label.id)}">
          <Label active=true>{label.title}</Label>
        </span>
      {:else}
        <span on:click="{()=>addToFilter(label.id, true)}">
          <Label>{label.title}</Label>
        </span>
      {/if}
    {/each}
  </div>
{/if}

<style>
.eshop_filter_labels { margin:-4.5px 50px 0px 50px; float:left; width:70%; }
</style>
