<script>
  import { Link } from "svelte-routing";
  import Loading from "../components/Loading.svelte";
  import Box from "../components/Box.svelte";
  import AddToCart from "../components/AddToCart.svelte";
  import Label from '../components/Label.svelte';
  import ParamTable from '../components/ParamTable.svelte';
  import InStock from '../components/InStock.svelte';
  import { api_url, path, real_path } from "../ConfigService.js";

  export let katId="";
  export let podkatId="";
  export let objectId="";
  export let returnPage=0;
  export let config;
  export let cartData;
  export let loginData;

	const data = (async () => {
		const response = await fetch($api_url+'?prod='+objectId)
    return await response.json()
	})()
</script>

<br style="clear:both"><br>
{#await data}
	<Loading />
{:then data}
  <div class="navigation_bar">
    <Link to="{$path}">{config.text.title}</Link> -&gt;
    <Link to="{$path}{data.podkategorie.kategorie_url}">{data.podkategorie.kategorie}</Link> -&gt;
    <Link to="{$path}{data.podkategorie.kategorie_url}/{data.podkategorie.url}/{returnPage}">{data.podkategorie.nazev}</Link>-&gt;
    produkt
  </div><br>
  <Box width="90%" maxWidth="1100" marginAuto="true" className="eshop_produkt_detail">
      <h2 class="nadpis">
        {data.data.nazev}
     </h2>
     {#if data.data.img}
      <img src="/{$real_path}{config.folder}/{data.data.img}" alt="{data.data.nazev}" width="100%" align="left" class="eshop_product_img">
     {:else}
      <img src="/{$real_path}{config.noimg}" alt="{data.data.nazev}" width="100" align="left" class="eshop_product_img">
     {/if}
      <div class="eshop_produkt_detail_right">
       {#if data.data.cena!=0}
          <div class="eshop_price">{config.text.price} {data.data.cena},-Kč</div><br>
       {/if}
       <InStock count={data.data.pocet} mode={config.showInStock} />
       <AddToCart id={data.data.id} text={config.text.addToCart} favoriteList={data.data.favorite} bind:cartData bind:loginData />
     </div>
     <div class="eshop_produkt_detail_center">
       <h4>{data.data.vyrobce}</h4>
       {#if data.data.ean}
        <p><b>EAN:</b> {data.data.ean}</p>
       {/if}
       {#if data.data.zkraceny_popis}
        <p>{data.data.zkraceny_popis}</p>
       {/if}

       {#if data.data.params}
          <br>
         {#each data.data.params as param}
          {#if param.type=="checkbox" && param.value && param.value!="false"}
             <Label passive=true>{param.name}</Label>
          {/if}
         {/each}
         <br>
       {/if}

      {#if data.data.popis}
       <p>{data.data.popis}</p>
      {/if}
      {#if data.data.varovani}
       <p>{data.data.varovani}</p>
      {/if}
      {#if data.data.davkovani}
       <p>{data.data.davkovani}</p>
      {/if}
     <small>  </small>
    </div>
    <br style="clear:both">
    <ParamTable data={data.data.params} />
  </Box>
  <br style="clear:both"><br>
    {#if katId=="k"}
      <Link to="{$path}cart">Zpět na košík</Link>
    {:else if katId=="o"}
      <Link to="{$path}cart/orders">Zpět na objednávky</Link>
    {:else if katId=="f"}
      <Link to="{$path}cart/favorites">Zpět na oblíbené</Link>
    {:else}
      <Link to="{$path}{katId}/{data.podkategorie.url}/{returnPage}">Zpět na položky</Link>
     {/if}
{:catch error}
	<p>Chyba načítání!</p>
{/await}

<style>
/*.eshop_produkt_detail { width:90%; max-width:950px; margin:auto; }*/
.eshop_product_img { margin-right:30px; }
.eshop_produkt_detail_center { float:left; width:400px; }
.eshop_produkt_detail_right { float:right; width:200px; }
</style>
