<script>
  import { Link } from "svelte-routing";
  import { Button, Textfield, Dialog, Checkbox } from 'svelte-mui/src';
  import Notify from '../components/Notify.svelte';
  import Textarea from '../components/Textarea.svelte';
  import Select from 'svelte-select';
  import Item from '../components/Cities.svelte';
  import loadOptions from '../components/Cities.js';
  import CartTransportDialog from './CartTransportDialog.svelte';
  import { api_url, bgColor, textColorInBg, headerColor } from "../ConfigService.js";


  export let visibleAdressDialog = false;
  export let visibleTransportDialog = false;
  export let loginData;
  export let cartData;
  export let onlyLogin=false;
  export let onlyEdit=false;
  export let email="";
  export let pw="";
  export let pw_retry="";
  export let config;
  let city = false;
  let error=false;
  let visibleRegisterSuccess=false;
  let gdpr=false;
  let messageRegisterSuccess="Registrace proběhla úspěšně!";
  let gdprAccept="Souhlasím se zpracováním osobních údajů dle GPRD";
  if(config.text.gdprAccept) { gdprAccept=config.text.gdprAccept; }


  let inputs=[
    { name: 'jmeno', title: config.text.firstname, type: 'text', required:true, value:"" },
		{ name: 'prijmeni', title: config.text.lastname, type: 'text', required:true, value:"" },
		{ name: 'email', title: 'e-mail', type: 'hidden', required:false, value:email },
    { name: 'telefon', title: config.text.phone, type: 'tel', required:true, value:"" },
		{ name: 'ulice', title: config.text.ulice, type: 'text', required:true, value:"" },
    { name: 'mesto', title: config.text.city, type: 'hidden', required:true },
    { name: 'psc', title: config.text.psc, type: 'number', required:true, value:"" },
		{ name: 'poznamka', title: config.text.note, type: 'textarea', required:false, value:"" }
  ];

  function updateDataAdress(values) {
    let mesto=""; let psc="";
    if(values) {
      for(let i=0; i<inputs.length; i++) {
        if(values[inputs[i].name]) {
          inputs[i].value = values[inputs[i].name];
          if(inputs[i].name=="mesto") { mesto = values[inputs[i].name]; }
          if(inputs[i].name=="psc") { psc = values[inputs[i].name]; }
        }
      }
    }
    if(mesto || psc) { city = {mesto: mesto, cast: false, psc: psc}; }
  }

  async function register() {
    const res = await fetch($api_url+'?customer=true&register=true', {
			method: 'POST',
			body: JSON.stringify({
        inputs: inputs,
        email: email,
        mesto: city.mesto + (city.cast ? ' - ' + city.cast :""),
        psc: city.psc,
				pw: pw,
        pw_retry: pw_retry
			})
		})
  }

  async function saveEdit() {
    const res = await fetch($api_url+'?customer=true&updateData=true', {
			method: 'POST',
			body: JSON.stringify({
        inputs: inputs,
        mesto: city.mesto + (city.cast ? ' - ' + city.cast :""),
        psc: city.psc
			})
		})
    let out=await res.json();
    if(await out) { if(await out.status) {messageRegisterSuccess=await out.status;} }
  }

  function send() {
    error=false;
    for(let i=0; i<inputs.length; i++) {
      if(inputs[i].required && inputs[i].title){
        if(inputs[i].name=="mesto" || inputs[i].name=="psc") {
          //if(!city) { error="Vyplňte prosím všechna označená pole!" }

        } else if(!inputs[i].value) {
          error="Vyplňte prosím všechna označená pole!";
        }
      }
    }
    if((onlyLogin || pw_retry && pw==pw_retry) && !gdpr && !onlyEdit) {
      error="Po pokračování je třeba souhlasit s GDPR!";
      if(config.text.gdprError) { error=config.text.gdprError; }
    }
    if(!error) {
      if(onlyLogin) {
        register();
        if(config.text.registerSuccess) { messageRegisterSuccess=config.text.registerSuccess; }
        visibleRegisterSuccess=true;
        visibleAdressDialog=false;
      } else if(onlyEdit) {
        saveEdit();
        messageRegisterSuccess="Osobní údaje byly změněny!";
        visibleRegisterSuccess=true;
        visibleAdressDialog=false;
      } else {
        if(pw_retry!="") { register(); }
        visibleAdressDialog=false;
        visibleTransportDialog=true;
      }
    }
  }

  $: {
    if(loginData) {
      updateDataAdress(loginData);
    }
  }

  const getOptionLabel = (option) => (option.mesto ? option.mesto :"");
  const getSelectionLabel = (option) => (option.mesto ? option.mesto :"") + (option.cast ? ' - ' +  option.cast :"");
</script>


<Dialog width="520" bind:visible={visibleAdressDialog} style="color:black;--divider:{$headerColor}">
    <div slot="title">Vyplňte dodací údaje</div>
    {#if !onlyLogin && !onlyEdit}
      <small><center>2 / 3</center></small>
    {/if}

{#each inputs as col}
  {#if col.name=="mesto"}
    <br>
    <Select {loadOptions} {getSelectionLabel} {getOptionLabel} {Item} placeholder="{config.text.city} *" noOptionsMessage="Nenalezeno" bind:selectedValue={city} inputStyles="background-color:rgba(0,0,0,0.1)" />
  {:else if col.name=="poznamka" && onlyLogin }
    &nbsp;
  {:else if col.title && col.name=="psc" && city && col.type!="hidden"}
      <br>
      <Textfield
          type={col.type}
          name={col.name}
          autocomplete="on"
          bind:value={city.psc}
          label={col.title}
          message="Vyplňte {col.title.toLowerCase()}"
          required={col.required}
      />
  {:else if col.title && col.type=="textarea"}
  <Textarea
      type={col.type}
      name={col.name}
      bind:value={col.value}
      label={col.title}
      message="Vyplňte {col.title.toLowerCase()}"
      required={col.required}
  />
  {:else if col.title && col.type!="hidden"}
    <Textfield
        type={col.type}
        name={col.name}
        autocomplete="on"
        bind:value={col.value}
        label={col.title}
        message="Vyplňte {col.title.toLowerCase()}"
        required={col.required}
    />
  {/if}
{/each}
  {#if onlyLogin || pw_retry && pw==pw_retry && !onlyEdit}
    <Checkbox bind:checked={gdpr}>
      <span>
        {gdprAccept}
      </span>
    </Checkbox>
  {/if}
  {#if error}
  <div class="eshop_error">{error}</div>
  {/if}
    <div slot="actions" class="actions center">
      {#if onlyLogin }
        <Button outlined on:click={send} color={$bgColor}>Zaregistrovat se</Button>
      {:else if onlyEdit }
        <Button outlined on:click={send} color={$bgColor}>Uložit změny</Button>
      {:else}
        <Button outlined on:click={send} color={$bgColor}>Pokračovat na přepravu</Button>
      {/if}
    </div>
</Dialog>

<CartTransportDialog bind:visibleTransportDialog bind:email bind:city bind:inputs bind:cartData config={config} />

<Notify bind:notify={visibleRegisterSuccess}>{messageRegisterSuccess}</Notify>

<style>
.eshop_error { color:red; }
</style>
