<script>
  import { Button, Textfield } from 'svelte-mui/src';
  import NavLink from "./NavLink.svelte";
  import Tooltip from '../components/Tooltip.svelte';
  import Notify from '../components/Notify.svelte';
  import IoIosCart from 'svelte-icons/io/IoIosCart.svelte';
  import IoIosHeart from 'svelte-icons/io/IoIosHeart.svelte'
  import IoIosHeartEmpty from 'svelte-icons/io/IoIosHeartEmpty.svelte'
  import { api_url, path, textColorInBg } from "../ConfigService.js";

  export let id = 0;
  export let loginData;
  export let cartData;
  export let favoriteList;
  let message = "Přidáno do košíku!";
  export let text = "přidat do košíku";
  let notify = false;
  let value = 1;
  let error = "";
  let maxItems=100;
  export let isFavorite=false;

  $: {
      if(value<=0) { value=1; }
      else if(value>maxItems) { value=maxItems; error="max "+maxItems; }
   }

   async function buy () {
     const res = await fetch($api_url+'?buy='+id+'&count='+value);
     notify=true;
     message = "Přidáno do košíku!";
     cartData=false;
  }
  async function addToFavorites () {
    const res = await fetch($api_url+'?favorite='+id);
    notify=true;
    message = "Přidáno mezi oblíbené!";
    cartData=false;
    isFavorite=true;
 }
 async function removeFromFavorites () {
   const res = await fetch($api_url+'?favorite='+id+"&remove=true");
   notify=true;
   message = "Odstraněno z oblíbených!";
   cartData=false;
   isFavorite=false;
}

async function checkIsFavorite(i, fl) {
  if(fl) {
    if(fl.id_eshop_produkty==i) { isFavorite=true; }
    else if(fl.length>0) {
      fl.forEach(async function(f) {
          if(f.id_eshop_produkty==i) { isFavorite=true; }
      })
    }
  }
}

$: {
  checkIsFavorite(id, favoriteList);
}
</script>
<br style="clear:both">
<div class="eshop_addToCart">
  <div class="eshop_cart_button "><br>
    <Tooltip tip={text} bottom>
      &nbsp;&nbsp;<Button outlined on:click={buy}><IoIosCart /></Button>
    </Tooltip>
  </div>
  <div style="float:right;width:60px;">
    <Textfield type="number" label="Počet:" name="count" bind:value required class="countNumber" error={error}></Textfield>
  </div>
  <div class="eshop_cart_button"><br>
    {#await loginData}
      &nbsp;
    {:then loginData}
      {#if loginData}
        {#if loginData.id}
            <div class="eshop_icon">
            {#if !isFavorite}
              <Tooltip tip="přidat do oblíbených produktů" bottom>
                <Button outlined on:click={addToFavorites}><IoIosHeartEmpty /></Button>
              </Tooltip>
            {:else}
              <Tooltip tip="odebrat z oblíbených produktů" bottom>
                <Button outlined on:click={removeFromFavorites}><IoIosHeart /></Button>
              </Tooltip>
            {/if}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        {/if}
      {/if}
    {/await}
  </div>
</div>
<br style="clear:both">


<Notify bind:notify>{message}
<NavLink to="{$path}cart" style="color:{$textColorInBg}">
 Přejít do košíku
</NavLink></Notify>

<style>
.eshop_addToCart { float:right; width:200px; }
.eshop_cart_button {float:right; max-width: 60px; margin-top:-12px; }
</style>
