<script>
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";
  import { Button, Dialog, Radio } from 'svelte-mui/src';
  import Notify from '../components/Notify.svelte';
  import Loading from "../components/Loading.svelte";
  import { api_url, bgColor, textColorInBg, headerColor } from "../ConfigService.js";

  export let visibleTransportDialog = false;
  export let cartData;
  export let email="";
  export let inputs;
  export let city="";
  export let config;
  let group="transport";
  let price=0;

  let notify = false;
  let message;

  async function send () {
		const res = await fetch($api_url+'?cart=true', {
			method: 'POST',
			body: JSON.stringify({
        email: email,
        inputs,
        mesto: city.mesto + (city.cast ? ' - ' + city.cast :""),
        psc: city.psc,
				platba: group
			})
		})
    message="Objednávka byla odeslána.";
    visibleTransportDialog=false;
    notify=true;
    cartData = false;
    let json = await res.json();
    if(await json.post.status) { message=await json.post.status; }
    cartData = await json;
  }
</script>

<Dialog width="520" bind:visible={visibleTransportDialog} style="color:black;--divider:{$headerColor}">
    <div slot="title">Vyberte způsob dopravy</div>
    <small><center>3 / 3</center></small>
    <br>
    {#await cartData}
    	<p><Loading /></p>
    {:then cartData}
      {#if cartData && cartData.doprava}
        {#each cartData.doprava as trans}
          <div class="eshop_transport">

                <div class="eshop_transport_type">{trans.nazev}</div>
                <div class="eshop_transport_price">{trans.cena},-Kč</div>

          </div>
          {#each trans.platba as pay}
            <div class="eshop_payment">
            <Radio bind:group value={pay.id} on:click={() => price=parseInt(trans.cena)+parseInt(pay.cena)}>
                <div class="eshop_transport_type">{pay.nazev}</div>
                <div class="eshop_transport_price">{pay.cena},-Kč</div>
            </Radio>
            </div>
          {/each}
        {/each}
       {/if}
      <br>
      {#if cartData && cartData.price}
        <b>Celková cena včetně dopravy: {price+parseInt(cartData.price)},-Kč</b>
      {/if}
      <br>
    {:catch error}
    	<p>Chyba načítání!</p>
    {/await}
    <div slot="actions" class="actions center">
        <Button outlined on:click={send} color={$bgColor}>Závazně objednat</Button>
    </div>

</Dialog>

<Notify bind:notify>{message}</Notify>

<style>
.label-text { min-width:350px; width:100%; }
.eshop_transport_type { min-width:200px; float:left; }
.eshop_transport_price { float:right; }
.eshop_payment { padding-left:20px; }

</style>
