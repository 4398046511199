<script>
  export let count;
  export let mode=true;
</script>

<div class="eshop_inStock_count">
  {#if mode=="text"}
    {#if count==0}
      Není skladem
    {:else if count==1}
      Poslední kus skladem
    {:else if count<5}
      Poslední kusy skladem
    {:else if count<99}
      Více jak 5 kusů skladem
    {:else}
      Mnoho kusů skladem
    {/if}
  {:else if mode=="count"}
    {#if count==0}
      Není skladem
    {:else}
      {count} ks skladem
    {/if}
  {:else}
    {#if count==0}
      Není skladem
    {:else}
      Skladem
    {/if}
  {/if}
</div>

<style>
.eshop_inStock_count { text-transform: uppercase; text-align:center; font-weight:bold; font-size:85%; }
</style>
