<script>
  import { Router, Route, Link } from "svelte-routing";
	import Loading from "./components/Loading.svelte";
  import CartInfo from "./components/CartInfo.svelte";
  import LoginInfo from "./components/LoginInfo.svelte";
  import SearchInput from "./components/SearchInput.svelte";

  import Kategorie from "./routes/Kategorie.svelte";
	import Podkategorie from "./routes/Podkategorie.svelte";
	import Produkty from "./routes/Produkty.svelte";
	import Produkt from "./routes/Produkt.svelte";
  import Search from "./routes/Search.svelte";

  import Cart from "./routes/Cart.svelte";
	import Orders from "./routes/Orders.svelte";
  import Favorites from "./routes/Favorites.svelte";

  import { path } from "./ConfigService.js";

  import IoIosCart from 'svelte-icons/io/IoIosCart.svelte';

  export let config;
  export let loginData;
  export let cartData;

  export let orderBy=false;
  export let orderType="ASC";
  export let returnPage=0;
  export let search="";
  export let url = "";
</script>

{#if config && loginData}
<Router url="{url}">
<header class="site-header sticky-top py-1 bg-dark">
  <nav class="container d-flex flex-column flex-md-row justify-content-between">

    <span class="h5 text-white font-weight-bold py-2" style="letter-spacing:3px"><Link to="/" style="color:white;">{config.text.title}</Link></span>

    <a class="py-2 d-none d-md-inline-block" href="#">Muži</a>
    <a class="py-2 d-none d-md-inline-block" href="#">Ženy</a>
    <a class="py-2 d-none d-md-inline-block" href="#">Dárkový poukaz</a>
    <a class="py-2 d-none d-md-inline-block" href="#">Kontakt</a>

    <span class="py-2 d-none d-md-inline-block"><Link to="{$path}cart" style="color:white;">
      <div style="width:32px;float:right;" on:click="{()=>(location.href='#eshop')}"><IoIosCart /></div>
      <CartInfo config={config} bind:cartData title="" showCountText=false />
    </Link></span>
  </nav>
</header>

<div style="margin-right:15px"><LoginInfo bind:loginData bind:url config={config} /></div>
<div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
    <div class="col-md-5 p-lg-5 mx-auto my-5">
      <h1 class="display-4 fw-normal">{config.text.title}</h1>
      <p class="lead fw-normal">Úvodní banner a text</p>
      <a class="btn btn-outline-secondary" href="#">Více</a>
    </div>
  </div>

  <div class="d-md-flex flex-md-equal w-100 my-md-3 ps-md-3">
    <div class="bg-dark me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden" style="width:50%">
      <div class="my-3 py-3">
        <h2 class="display-5">Pánské</h2>
        <p class="lead text-white"><a href="#">Trička</a>&nbsp;&nbsp;<a href="#">Kalhoty</a>&nbsp;&nbsp;<a href="#">Boty</a></p>
      </div>
      <div class="bg-white shadow-sm mx-auto overflow-hidden" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;text-align:center;"><img src="1.jpg" width="100%" /></div>
    </div>
    <div class="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden" style="width:50%">
      <div class="my-3 p-3">
        <h2 class="display-5">Dámské</h2>
        <p class="lead"><a href="#">Trička</a>&nbsp;&nbsp;<a href="#">Kalhoty</a>&nbsp;&nbsp;<a href="#">Boty</a></p>
      </div>
      <div class="bg-white shadow-sm mx-auto overflow-hidden" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;text-align:center;"><img src="2.jpg" width="100%" /></div>
    </div>
  </div>

<div id="eshop" class="position-relative overflow-hidden p-3 p-md-5 m-md-3">
      <SearchInput bind:search config={config} />
      <br style="clear:both">
  		<div>
  			<Route path="/">
  				<Produkty objectId="novinky" katId=false config={config} bind:returnPage bind:loginData bind:cartData bind:orderBy bind:orderType showFilters="false" showNavigation=false showSubcategories=false />
  			</Route>
        <Route path="/shop">
  				<Produkty objectId="novinky" katId=false config={config} bind:returnPage bind:loginData bind:cartData bind:orderBy bind:orderType showFilters="false" showNavigation=false showSubcategories=false />
  			</Route>
        <Route path="{$path}">
  				<Kategorie config={config} />
  			</Route>
  			<Route path="{$path}:id" let:params >
  				<Podkategorie objectId={params.id} config={config} />
  			</Route>
  			<Route path="{$path}:kat/:id" let:params >
  				<Produkty objectId={params.id} katId={params.kat} config={config} bind:returnPage bind:loginData bind:cartData bind:orderBy bind:orderType />
  			</Route>
        <Route path="{$path}:kat/:id/:page" let:params >
          <Produkty objectId={params.id} katId={params.kat} page={params.page} config={config} bind:returnPage bind:loginData bind:cartData bind:orderBy bind:orderType />
  			</Route>
  			<Route path="{$path}:kat/:podkat/p/:id" let:params >
  				<Produkt objectId={params.id} katId={params.kat} podkatId={params.podkat} config={config} bind:returnPage bind:loginData bind:cartData />
  			</Route>

        <Route path="{$path}search/:id" let:params >
  				<Search bind:search searchId={params.id} config={config} />
  			</Route>
        <Route path="{$path}search/:id/:page" let:params >
  				<Search bind:search searchId={params.id} page={params.page} config={config} />
  			</Route>

        <Route path="{$path}cart">
  				<Cart config={config} bind:cartData bind:loginData />
  			</Route>
        <Route path="{$path}cart/orders">
  			  <Orders bind:cartData config={config} />
  			</Route>
        <Route path="{$path}cart/favorites">
          <Favorites config={config} bind:loginData bind:cartData />
  			</Route>

  		</div>
      <br><br>
  </div>
</Router>
{:else}
  <Loading />
{/if}

<style>
.text-white a { color:white; }
.site-header a {
  color: #8e8e8e;
  transition: color .15s ease-in-out;
  text-decoration: underline;
}
.site-header a:hover { color:white; text-decoration: none; }
a { cursor:pointer; }
</style>
