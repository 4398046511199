<script>
  import { Link } from "svelte-routing";
  import Box from "../components/Box.svelte";
  import Loading from "../components/Loading.svelte";
  import { api_url, path, categoryColor } from "../ConfigService.js";

  export let config;
  let box_color="#6f4a5d";
  if($categoryColor) { box_color=$categoryColor; }

	const data = (async () => {
		const response = await fetch($api_url)
    return await response.json()
	})()
</script>

{#if config.text.title}
  <h2 class="nadpis">{config.text.title}</h2>
{/if}
{#await data}
	<Loading />
{:then data}
  <div class="eshop_kategorie">
  {#each data as kat}
    <Box width="300">
    <div class="eshop_kategorieBox" style="--box-color: {box_color}">
	   <h3>
       <Link to="{$path}{kat.url}">
        {kat.nazev}
       </Link>
     </h3>
     {#if kat.img}
      <Link to="{$path}{kat.url}"><img src="{config.folder_kat}/{kat.img}" alt="{kat.nazev}" width="100%"></Link>
     {:else}
         <ul>
         {#each kat.kategorie as pk}
           <li><Link to="{$path}{kat.url}/{pk.url}">
            {pk.nazev}
           </Link></li>
         {/each}
         </ul>
     {/if}
    </div>
    </Box>
  {/each}
  </div>
{:catch error}
	<p>Chyba načítání!</p>
{/await}

<style>
.eshop_kategorie { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; border-radius: 0.5rem; }
.eshop_kategorieBox h3 { color:white; background:var(--box-color); width:100%; margin:-10px; margin-bottom:5px; padding:10px; text-align:center; text-transform: uppercase;  border-radius: 0.5rem 0.5rem 0 0; }
.eshop_kategorieBox a { display:block; }
</style>
