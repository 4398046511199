<script>
  import { Link } from "svelte-routing";
  import { Button, Textfield, Dialog } from 'svelte-mui/src';
  import Notify from '../components/Notify.svelte';
  import CartAdressDialog from './CartAdressDialog.svelte';
  import { headerColor} from "../ConfigService.js";
  import { api_url, bgColor, textColorInBg } from "../ConfigService.js";

  export let visibleDialog = false;
  export let visibleAdressDialog = false;
  export let onlyLogin=false;
  export let cartData;
  export let loginData;
  export let config;
  let email="";
  let pw="";
  let pw_retry="";
  export let loginVisible=false;
  export let registerVisible=false;
  let isLogged=false;
  let error="";
  let error2="";
  export let visibleLoginSuccess=false;
  let messageLoginSuccess="Byl(a) jste úspěšně přihlášen!"

  function checkEmail (value) {
      if(!(value && !!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) {
        error="E-mail je ve špatném formátu!";
      } else { error=""; }
  }

  async function sendLogin () {
		const res = await fetch($api_url+'?customer=true', {
			method: 'POST',
			body: JSON.stringify({
        email: email,
        pass: pw
			})
		})
    loginData=await res.json();
    if(await !loginData.id) { messageLoginSuccess="Přihlášení se nezdařilo! Špatné přihlašovací údaje!"; }
  }

  function loginStart() {
    if(!error && !error2) {
      if(loginVisible) {
        let answer=sendLogin();
        if(answer.id) {
          error="Neplatné přihlašovací údaje!";
        } else {
          visibleDialog=false;
          visibleAdressDialog=true;
        }
      } else {
        if(!email) { error="Vyplňte váš e-mail"; }
        else if(onlyLogin) {
          let answer=sendLogin();
          if(answer.id) {
            error="Neplatné přihlašovací údaje!";
          } else {
            visibleDialog=false;
            visibleLoginSuccess=true;
            if(config.text.loginSuccess) { messageLoginSuccess=config.text.loginSuccess; }
          }
        } else { loginVisible=true; }
      }
    }
  }
  function registerStart() {
    if(!error && !error2) {
      if(registerVisible) {
        if(pw!=pw_retry) {
          error2="Hesla se neshodují!";
        } else {
          error2="";
          visibleAdressDialog=true;
          visibleDialog=false;
        }

      } else {
        if(!email) { error="Vyplňte váš e-mail"; }
        else { registerVisible=true; }
      }
    }
  }
  function adressStart() {
    if(!error && !error2) {
      if(!email) { error="Vyplňte váš e-mail"; }
      else {
        visibleAdressDialog=true;
        visibleDialog = false;
      }
    }
  }

  async function checkIsLoged(data) {
    if(data.id && data.email) {
      email = data.email;
      isLogged = true;
    }
  }

  $: {
    checkIsLoged(loginData);
  }

</script>

<Dialog width="520" bind:visible={visibleDialog} style="color:black;--divider:{$headerColor}">
    <div slot="title">Začnětě zadáním vašeho e-mailu</div>
    {#if !onlyLogin}
      <small><center>1 / 3</center></small>
    {/if}

    <Textfield
        type="email"
        name="email"
        autocomplete="on"
        required
        bind:value={email}
        bind:error
        on:change={() => checkEmail (email)}
        label="e-mail"
        message="Vyplňte váš e-mail"
    />
    {#if loginVisible || registerVisible || onlyLogin}
      <Textfield
          type="password"
          name="pass"
          autocomplete="off"
          required
          bind:value={pw}
          bind:error={error2}
          label="heslo"
          message="Vyplňte vaše heslo"
      />
    {/if}
    {#if registerVisible}
      <Textfield
          type="password"
          name="pass2"
          autocomplete="off"
          required
          bind:value={pw_retry}
          label="zopakujte heslo"
          message="Vyplňte znova vaše heslo"
      />
    {/if}

    <div slot="actions" class="actions center">
        {#if isLogged}
          <Button outlined on:click={adressStart} color={$bgColor}>Pokračovat</Button>
        {:else if !loginVisible && !onlyLogin}
          <Button outlined on:click={adressStart} color={$bgColor}>Nakoupit bez registrace</Button>
        {/if}
    </div>
    <div slot="actions" class="actions center">
      {#if !registerVisible && !isLogged}
        <Button outlined on:click={loginStart} color={$bgColor}>Přihlásit se</Button>&nbsp;&nbsp;
      {/if}
      {#if !loginVisible && !isLogged}
        <Button outlined on:click={registerStart} color={$bgColor}>Registrovat se</Button>
      {/if}
    </div>
</Dialog>

<CartAdressDialog bind:visibleAdressDialog bind:email bind:pw bind:pw_retry bind:onlyLogin bind:loginData bind:cartData config={config} />

<Notify bind:notify={visibleLoginSuccess}>{messageLoginSuccess}</Notify>

<style>
.outlined { height:60px; }
</style>
