<script>
  import FilterBox from "../components/FilterBox.svelte";
  import { Button, Sidepanel } from 'svelte-mui/src';
  import { bgColor, headerColor } from "../ConfigService.js";

    let data;
    export let apiUrl;
    export let config;
    export let filter;
    export let lastFilter;
    export let visible=false;
    let productId=[];

    function runFilter() {
      if(filter=="") { filter="false"; }
      lastFilter=false;
      visible=false;
    }

    function deleteFilter() {
      filter="false";
      visible=false;
    }

    function getValues(dat, values) {
        let out=[]
        if(dat && values) {
          for(let i=0; i<dat.length; i++) {
            if(values[dat[i].id]) {
                out.push({id:dat[i].id, value: values[dat[i].id]});
            }
          }
        }
        return out;
	  }

    async function update() {
      if(visible) {
        const res = await fetch(apiUrl);
        data = await res.json();
      }
    }
$: {
  update(visible)
}
    /*
    //řešeno jako labels
    {#if data.filters['checkbox'] && data.filters['checkbox'].length>0}
      {#each data.filters['checkbox'] as checkbox}
        <FilterBox title={checkbox.title} values={getValues(data.data, checkbox.values)} id={checkbox.id}  type="checkbox" bind:filter />
      {/each}
    {/if}
    */
</script>

<Sidepanel left bind:visible style="color:black;--bg-color: {$headerColor}">
{#if data}
  <div class="eshop_filters">
    <h3>Filtrování produktů</h3>
    {#if config.filterManufacturer}
      <FilterBox title="Výrobci" values={data.info.vyrobce} id="manufacturer" type="checkbox" bind:filter />
    {/if}
    {#if config.filterPrice}
      <FilterBox title="Cena" values={data.info.minPrice} to={data.info.maxPrice} id="price" type="range" bind:filter />
    {/if}

    {#if data.filters}
      {#if data.filters['list'] && data.filters['list'].length>0}
        {#each data.filters['list'] as list}
          <FilterBox title={list.title} values={getValues(data.data, list.values)} id={list.id} type="checkbox" bind:filter />
        {/each}
      {/if}
      {#if data.filters['int'] && data.filters['int'].length>0}
        {#each data.filters['int'] as int}
          <FilterBox title={int.title} values={getValues(data.data, int.values)} id={int.id} type="int" bind:filter />
        {/each}
      {/if}
    {/if}
      <br><br><br>
      <center>
      <Button on:click={runFilter} color={$bgColor} outlined>Filtrovat</Button>
      <br><br>
      <Button on:click={deleteFilter} color={$bgColor} outlined>Zrušit filtrování</Button>
      </center>
  </div>
{/if}
</Sidepanel>

<style>
.eshop_filters { padding-left:20px; float:left; width:200px; color:black; }
</style>
