<script>
  import { Link } from "svelte-routing";
  import Loading from "../components/Loading.svelte";
  import Box from "../components/Box.svelte";
  import InStock from '../components/InStock.svelte';
  import { Button, Snackbar } from 'svelte-mui/src';

  import IoMdRemoveCircle from 'svelte-icons/io/IoMdRemoveCircle.svelte'
  import IoIosRemove from 'svelte-icons/io/IoIosRemove.svelte'
  import IoIosAdd from 'svelte-icons/io/IoIosAdd.svelte'

  import Tooltip from '../components/Tooltip.svelte';
  import CartEmailDialog from '../dialog/CartEmailDialog.svelte';
  import Notify from '../components/Notify.svelte';
  import { api_url, path, real_path, bgColor, boxColor } from "../ConfigService.js";

  export let config;
  export let cartData;
  export let loginData;
  let message = "Počet položek byl upraven!";
  let notify = false;
  export let visibleDialog=false;
  export let loginVisible=false;
  export let registerVisible=false;
  let box_color="#6f4a5d";
  if($boxColor) { box_color=$boxColor; }

   async function plusItem (id) {
     const res = await fetch($api_url+'?cart=true&buy='+id+'&count=plus');
     cartData = await res.json();
     message="Počet položek byl upraven!";
     notify=true;
  }

  async function minusItem (id) {
    const res = await fetch($api_url+'?cart=true&buy='+id+'&count=minus');
    cartData = await res.json();
    message="Počet položek byl upraven!";
    notify=true;
 }

 async function removeItem (id) {
   const res = await fetch($api_url+'?cart=true&removeFromCart='+id);
   cartData = await res.json();
   message="Položka byla odebrána z košíku!";
   notify=true;
 }

 function showBuyDialog() {
   visibleDialog = true;
   loginVisible=false;
   registerVisible=false;
 }
</script>

<div class="navigation_bar">
  <Link to="{$path}">{config.text.title}</Link> -&gt;
  {config.text.cart}
</div>
<h2 class="nadpis">{config.text.cart}</h2>
{#await cartData}
	<Loading />
{:then cartData}
  <div class="eshop_cart">
  {#if cartData.data}
  {#each cartData.data as p}
    <Box className="eshop_cartBox mgb" width="90%" maxWidth="1200" marginAuto=true>
      <div class="eshop_cartLeft">
        {#if p.img}
         <img src="/{$real_path}{config.folder}/{p.img}" alt="{p.nazev}" width="80" align="left">
        {:else}
         <img src="/{$real_path}{config.noimg}" alt="{p.nazev}" width="80" align="left">
        {/if}
        <h3>
          <Link to="{$path}k/p/p/{p.url}">
           {p.nazev}
          </Link>
        </h3>
        <h4>{p.vyrobce}</h4>
        {#if p.pocet}
          <InStock count={p.pocet} mode={config.showInStock} />
        {/if}
      </div>
      <div class="eshop_cartRight">
        {#if p.cena!=0}
           <div class="eshop_price">Cena: {p.calc_cena},-Kč</div><br>
        {/if}
         Počet kusů: {p.calc_id}
         <Tooltip tip="zvýšit počet položek" bottom>
           &nbsp;&nbsp;<Button outlined on:click={() => plusItem(p.id)}><IoIosAdd /></Button>
         </Tooltip>
         <Tooltip tip="snížit počet položek" bottom>
           &nbsp;&nbsp;<Button outlined on:click={() => minusItem(p.id)}><IoIosRemove /></Button>
         </Tooltip>
         <Tooltip tip="odebrat z košíku" bottom>
           &nbsp;&nbsp;<Button outlined on:click={() => removeItem(p.id)}><IoMdRemoveCircle /></Button>
         </Tooltip>
      </div>
      <br style="clear:both"><br>
     </Box>
    {/each}
    {:else}
      <div class="eshop_error"><b>{config.text.nocart}</b></div>
    {/if}
    <br><br>
    <center>
    <div class="eshop_finalPrice"><b>Celková cena: {cartData.price},-Kč</b></div><br><br>
    {#if cartData.price!=0}
      <div class="eshop_button">
        <Button on:click={showBuyDialog} outlined raised color={$bgColor}>
         {config.text.cartFinish}
        </Button>
      </div>
    {/if}
    </center>
    <br>
  </div><br>
{:catch error}
	<p>Chyba načítání!</p>
{/await}

{#await loginData}
  &nbsp;
{:then loginData}
  <CartEmailDialog bind:visibleDialog bind:loginVisible bind:registerVisible loginData={loginData} bind:cartData config={config} />
{/await}
<Notify bind:notify>{message}</Notify>

<style>
.eshop_cartBox {
    border: 1px solid var(--box-color);
    margin: 10px auto 10px auto;
    padding: 10px;
    border-radius: 4px;
    text-align: left;
    min-height: 80px;
    max-width:1200px;
}
.eshop_cartLeft {
    width: 600px;
    float: left;
}
.eshop_cartRight {
    width: 350px;
    float: right;
    padding-top: 20px;
}
</style>
