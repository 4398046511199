<script>
  import RangeSlider from "svelte-range-slider-pips";

  export let min;
  export let max;
  export let values=[min,max];
  export let step=5;

  $: {
    if(min && max) {
      let percent=((max-min)/100);
      step=5*percent;
    }
    if(!values || values.length==0) {
      values=[min,max];
    }
  }
</script>

<div style="padding-left:20px">
 {#if min!=max}
  <RangeSlider {step} bind:values={values} {min} {max} first='label' last='label' />
  <div style="text-align:center;margin-top:-22px;">
    {Math.ceil(values[0])}
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    {Math.floor(values[1])}
  </div>
 {/if}
</div>
