<script>
  import { Button, Textfield, Dialog } from 'svelte-mui/src';
  import Notify from '../components/Notify.svelte';
  import { headerColor} from "../ConfigService.js";
  import { api_url, bgColor, textColorInBg } from "../ConfigService.js";

  export let visiblePwDialog=false;
  export let config;
  let pw="";
  let pw_retry="";
  let error="";
  let visibleChangeSuccess=false;
  let messageChangeSuccess="Heslo bylo úspěšně změněno!"

  async function sendPw () {
		const res = await fetch($api_url+'?customer=true&changePw=true', {
			method: 'POST',
			body: JSON.stringify({
        pw: pw,
        pw_retry: pw
			})
		})
    return await res.json();
  }

  function changePw() {
        if(pw!=pw_retry) {
          error="Hesla se neshodují!";
        } else {
          let answer=sendLogin();
          if(answer.id) {
            messageChangeSuccess=answer.id;
          }
          error="";
          visiblePwDialog=false;
          visibleChangeSuccess=true;
          sendPw();
        }
  }

</script>


<Dialog width="520" bind:visible={visiblePwDialog} style="color:black;--divider:{$headerColor}">
    <div slot="title">Zadejte nové heslo</div>
      <Textfield
          type="password"
          name="pass"
          autocomplete="off"
          required
          bind:value={pw}
          bind:error={error}
          label="heslo"
          message="Vyplňte vaše heslo"
      />
      <Textfield
          type="password"
          name="pass2"
          autocomplete="off"
          required
          bind:value={pw_retry}
          label="zopakujte heslo"
          message="Vyplňte znova vaše heslo"
      />

    <div slot="actions" class="actions center">
          <Button outlined on:click={changePw} color={$bgColor}>Změnit heslo</Button>
    </div>
</Dialog>

<Notify bind:notify={visibleChangeSuccess}>{messageChangeSuccess}</Notify>

<style>
.outlined { height:60px; }
</style>
