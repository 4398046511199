<script>
  import { boxColor, borderColor } from "../ConfigService.js";
  export let className="";
  export let width=320;
  export let marginAuto=false;
  let widthUnit="px";
  export let maxWidth="auto";
  let maxWidthUnit="";
  export let background=$boxColor;

  function isNumber(str) {
        var pattern = /^\d+$/;
        return pattern.test(str);
    }
$: {
  if(width) {
    if(width=="auto" || !isNumber(width)) { widthUnit=""; }
    else { widthUnit="px"; }
    if(maxWidth=="auto" || !isNumber(maxWidth)) { maxWidthUnit=""; }
    else { maxWidthUnit="px"; }
  }
}
function margin() {
  if(marginAuto) { return "margin-left:auto;margin-right:auto;"; }
  return "";
}
</script>

<div class="eshop_box shadow-xl px-4 p-3 rounded-lg {className}" style="width:{width}{widthUnit}; max-width:{maxWidth}{maxWidthUnit}; {margin()} --border-color: {$borderColor}; --bg-color: {background}">
  <slot />
</div>

<style>
a { cursor:pointer; }
.eshop_box { color:black; border:2px solid rgba(0, 0, 0, 0.04); margin:3px; padding-top:8px; padding-bottom:8px; background-color:var(--bg-color, #000); }
.eshop_box:hover { border:2px solid var(--border-color); }
.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.mgb {
    margin-bottom: 1rem;
}
.rounded-lg {
    border-radius: 0.5rem;
}
</style>
