<script>
  import { onMount } from "svelte";
  import NavLink from "./NavLink.svelte";
  import { api_url, path } from "../ConfigService.js";

  export let cartData;
  export let config;
  export let title;
  export let showCountText=true;
  if(title && title!="" && config) { if(config.text.cart) { title=config.text.cart; } }
  let count = 0;
</script>

<div class="shoppingCart">
  {#if title && title!=""}
    <NavLink to="{$path}cart">
     {title}
    </NavLink>
  {/if}
  {#await cartData}
  {:then cartData}
    {#if cartData.count && (cartData.price || cartData.price==0)}
      {#if showCountText}
        <i>(<span id="shoppingCartCount">{cartData.count})</i>
      {:else if cartData.count!=0}
    	 <i>(<span id="shoppingCartCount">{cartData.count}
       {#if cartData.count==1}
          položka
       {:else if cartData.count>=2 && cartData.count<5}
          položky
       {:else}
          položek
       {/if}
       {cartData.price},-Kč</span>)</i>
      {/if}
    {/if}
  {:catch error}

  {/await}
</div>

<style>
.shoppingCart { float:right; }
</style>
