<script>
  import { Link } from "svelte-routing";
  import { path } from "../ConfigService.js";

  export let count = 0;
  export let active = 1;

  export let kat="";
  export let podkat="";
/*
  export let maxPages=14;

   function getPages() {
   	let pages=[];

   	for(let i=(active-Math.ceil(maxPages/2)); i<=active; i++) {
   		if(i>0 && (i<=count)) { pages[pages.length]=parseInt(i); }
   	}
   	if(pages.length>0) { pages[pages.length]=0; }

   	for(let i=(active+1); i<(active+Math.ceil(maxPages/2)+1); i++) {
   		if(i<=count) { pages[pages.length]=parseInt(i); }
   	}

   	return pages;
   }

   {#if getPages()[0]!=1}
     <Link to="{$path}{kat}/{podkat}/1">1</Link>&nbsp;&nbsp;
     &middot;
     &nbsp;&nbsp;
   {/if}
   {#each getPages() as p}
   {#if active==p || active==0 && p==1}
     {p}
   {:else if p==0}
     &middot;
   {:else}
     <Link to="{$path}{kat}/{podkat}/{p}">{p}</Link>
   {/if}
   &nbsp;&nbsp;
   {/each}
   {#if getPages().slice(-1)[0]!=count && count && count>0 && count!=active}
     &middot;
     &nbsp;&nbsp;
     <Link to="{$path}{kat}/{podkat}/{count}">{count}</Link>
   {/if}
   */
</script>

{#if count>1}
<center>
  <div class="strankovani"><b>Stránky:</b>&nbsp;
    {#each {length: count} as _, i}
      {#if active==(i+1) || active==0 && i==0}
        &nbsp;
        <span class="strankovaniActive"><b>{i+1}</b></span>
        &nbsp;·
      {:else}
        &nbsp;
        <Link to="{$path}{kat}/{podkat}/{i+1}">
         {i+1}
        </Link>
        &nbsp;·
      {/if}

    {/each}
  </div>
</center>
{/if}

<style>
.strankovani { font-size:120%; }
</style>
