<script>
  import { Button, Snackbar } from 'svelte-mui/src';
  import { bgColor, textColorInBg } from "../ConfigService.js";

  export let notify = false;
  export let bg = $bgColor;
  export let color = $textColorInBg;
  export let timeout = 4;
</script>

<Snackbar bind:visible={notify} bg={bg} bottom timeout={timeout}>
    <slot />
    <span slot="action">
        <Button color={color} on:click={() => (notify = false)}>X</Button>
    </span>
</Snackbar>
