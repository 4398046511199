<script>
  import { Router, Route } from "svelte-routing";
  import NavLink from "./components/NavLink.svelte";
	import Loading from "./components/Loading.svelte";
  import CartInfo from "./components/CartInfo.svelte";
  import LoginInfo from "./components/LoginInfo.svelte";
  import SearchInput from "./components/SearchInput.svelte";

  import Kategorie from "./routes/Kategorie.svelte";
	import Podkategorie from "./routes/Podkategorie.svelte";
	import Produkty from "./routes/Produkty.svelte";
	import Produkt from "./routes/Produkt.svelte";
  import Search from "./routes/Search.svelte";

  import Cart from "./routes/Cart.svelte";
	import Orders from "./routes/Orders.svelte";
  import Favorites from "./routes/Favorites.svelte";

  import { path } from "./ConfigService.js";

  export let config;
  export let loginData;
  export let cartData;

  export let orderBy=false;
  export let orderType="ASC";
  export let returnPage=0;
  export let search="";
  export let url = "";

</script>

<div id="eshop">
  {#if config && loginData}
  	<Router url="{url}">
      <CartInfo config={config} bind:cartData />
      <LoginInfo bind:loginData bind:url config={config} />
      <SearchInput bind:search config={config} />
      <br style="clear:both">
  		<div>
  			<Route path="/">
  				<Kategorie config={config} />
  			</Route>
        <Route path="{$path}">
  				<Kategorie config={config} />
  			</Route>
  			<Route path="{$path}:id" let:params >
  				<Podkategorie objectId={params.id} config={config} />
  			</Route>
  			<Route path="{$path}:kat/:id" let:params >
  				<Produkty objectId={params.id} katId={params.kat} config={config} bind:returnPage bind:loginData bind:cartData bind:orderBy bind:orderType />
  			</Route>
        <Route path="{$path}:kat/:id/:page" let:params >
          <Produkty objectId={params.id} katId={params.kat} page={params.page} config={config} bind:returnPage bind:loginData bind:cartData bind:orderBy bind:orderType />
  			</Route>
  			<Route path="{$path}:kat/:podkat/p/:id" let:params >
  				<Produkt objectId={params.id} katId={params.kat} podkatId={params.podkat} config={config} bind:returnPage bind:loginData bind:cartData />
  			</Route>

        <Route path="{$path}search/:id" let:params >
  				<Search bind:search searchId={params.id} config={config} />
  			</Route>
        <Route path="{$path}search/:id/:page" let:params >
  				<Search bind:search searchId={params.id} page={params.page} config={config} />
  			</Route>

        <Route path="{$path}cart">
  				<Cart config={config} bind:cartData bind:loginData />
  			</Route>
        <Route path="{$path}cart/orders">
  			  <Orders bind:cartData config={config} />
  			</Route>
        <Route path="{$path}cart/favorites">
          <Favorites config={config} bind:loginData bind:cartData />
  			</Route>

  		</div>
      <br><br>
  	</Router>
  {:else}
    <Loading />
  {/if}
</div>

<style>
a { cursor:pointer; }
</style>
