<script>
  import { onMount } from 'svelte';
  import { Button, Checkbox } from 'svelte-mui/src';
  import RangeSlider from "./RangeSlider.svelte";

    export let title;
    export let id;
    export let type;
    export let values;
    export let to=false;
    export let value=[];
    export let filter;
    export let filterChanged=false;
    let load=false;

    function getFilterValue(id) {
      let f = filter.split(";");
      for(let i=0;i<f.length;i++) {
        if(f[i].includes(id+"=")) {
          let value = f[i].split("=");
          return value[1].split("$");
        }
      }
      return [];
    }

    function removeFromFilter(remove) {
      let f = filter.split(";");
      filter="";
      for(let i=0;i<f.length;i++) {
        if(!f[i].includes(remove+"=")) {
          if(filter!="" && filter!="false" && filter.slice(-1)!=";") { filter+=";"; }
          filter+=f[i];
        }
      }
      filterChanged=true;
    }

    function addToFilter(id, value) {
      let realValue=value;
      if(type=="int") { //řeší problém s nezadáním některý parametrů u int
        let valueEx=value.split("$");
        if(valueEx[0]==minValue(values) && valueEx[1]==maxValue(values)) { realValue=false; }
      }
      removeFromFilter(id);
      if(filter!="" && filter!="false" && filter.slice(-1)!=";") { filter+=";"; }
      if(realValue) { 
        if(filter=="false") { filter=""; }
        filter+=id+"="+realValue;
      }
      filterChanged=true;
    }

    function minValue(val) {
      let min=0;
      if(val) {
        for(let i=0; i<val.length; i++) {
          if(min>val[i].value || min==0) { min=val[i].value; }
        }
      }
      return min;
    }

    function maxValue(val) {
      let max=0;
      if(val) {
        for(let i=0; i<val.length; i++) {
          if(max<val[i].value || max==0) { max=val[i].value; }
        }
      }
      return max;
    }

 $: {
   if(value && id) {
     if(Array.isArray(value)) {
      if(value.length>0){
        addToFilter(id, value.join('$'));
      } else if(id && load && filter) {
        removeFromFilter(id);
      }
     } else {
       addToFilter(id, value);
     }
   //} else if(id) {
     //removeFromFilter(id);
   }
 }

 onMount(() => {
    if(filter) {
      value=getFilterValue(id);
      load=true;
    }
  });
</script>

<div class="eshop_filter_box">
  {#if type=="range"}
    {#if to>0}
      <br><h4>{title}</h4>
      <RangeSlider bind:values={value} min={values} max={to} />
    {/if}
  {:else if values}
    {#if values.length>0}
      {#if type=="int" && minValue(values)!=maxValue(values) || type!="int"}
        <br><h4>{title}</h4>
      {/if}
      {#if type=="int"}
        <RangeSlider bind:values={value} min={minValue(values)} max={maxValue(values)} />
      {:else}
          {#if values[0].id && type=="checkbox"}
            {#each values as item}
              <Checkbox class="thin" bind:group={value} value={item.id}>
                  <span>{item.value}</span>
              </Checkbox>
            {/each}

          {:else if type=="checkbox" && Array.isArray(values)}
            {#each values as item}
              <Checkbox class="thin" bind:group={value} value={item}>
                  <span>{item}</span>
              </Checkbox>
            {/each}
          {/if}
      {/if}
    {/if}
  {/if}
</div>

<style>
.eshop_filter_box {  }
</style>
