<script>
  import { bgColor } from "../ConfigService.js";
  import { Firework } from 'svelte-loading-spinners'

  export let visible=true;
</script>

{#if visible}
  <center><div class="loading"><Firework size="30" color={$bgColor} unit="px" duration="0.5s"></Firework></div></center>
{/if}
